import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  PrivacyPolicyLink,
  TermsOfServiceLink,
  AlertBox,
  Dialog,
  Pressable,
  Checkbox,
} from "@gonoodle/gn-universe-ui";
import {
  SECTIONS,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { useSearchParams } from "next/navigation";

import { withAuthorization } from "../containers";
import Layout from "../components/Layout";
import { EmailField, PasswordField } from "../components/FormElements";
import GoNoodleHeader from "../components/GoNoodleHeader";
import {
  ROUTE_PERMISSIONS,
  ROUTE_PATHS,
  OAUTH_INITIATORS,
  SSO_ERRORS,
  ROUTE_PREFIX,
  REGISTRATION_INITIATORS,
} from "../constants";
import { useLogEvent } from "../contexts/Analytics";
import {
  useLogin,
  useValidateEmail,
  useLoggedOutChampPoints,
  useIntendedPath,
} from "../hooks";
import { useUser } from "../contexts/user";
import SSOButton from "../components/SSOButton";
import RedirectToRegistrationButton from "../components/RedirectToRegistrationButton";
import Link from "../components/Link";

function LoginDialog({ title, description, email, onClose }) {
  const searchParams = useSearchParams();
  const fields = {
    email: "email",
    password: "password",
    importCleverProfiles: "importCleverProfiles",
  };
  const { validateEmail } = useValidateEmail();
  const { updateUserFlags } = useUser();
  const { resetLoggedOutChampPoints } = useLoggedOutChampPoints();

  const oauthInitiator = searchParams.get("oauth_initiator");

  const { logEvent: logCleverAccountConnectedEvent } = useLogEvent({
    event: "Clever Account Connected to GoNoodle Account",
    properties: {
      sourceElement: SECTIONS_TYPES.CLEVER,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logGoogleAccountConnectedEvent } = useLogEvent({
    event: "Google Account Connected to GoNoodle Account",
    properties: {
      sourceElement: SECTIONS_TYPES.GOOGLE,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { login, error, isLoading } = useLogin({
    redirectRoute: {
      pathname: `/${ROUTE_PREFIX.PROFILES}`,
      query: {
        registrationInitiator: REGISTRATION_INITIATORS.CLEVER,
      },
      referrer: {
        sourceElement: SECTIONS_TYPES.CLEVER,
        sourceName: SECTIONS_TYPES.LOGIN,
      },
    },
    onSuccess: (user, formValues) => {
      if (oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN) {
        logCleverAccountConnectedEvent();
      } else if (oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN) {
        logGoogleAccountConnectedEvent();
      }
      // TODO: Rollback flow if this fails
      updateUserFlags({
        importCleverProfiles: formValues[fields.importCleverProfiles],
      });
      resetLoggedOutChampPoints();
    },
  });

  return (
    <Dialog.Root isOpen={true} isDismissable={!isLoading} onClose={onClose}>
      <Dialog.Content className="w-full md:w-screen-xs p-md my-8 bg-white rounded-lg transform transition-all">
        <div className="flex flex-col space-y-md">
          <Dialog.Title className="gn-headline-sm text-center">
            {title}
          </Dialog.Title>

          <p className="gn-text text-center">{description}</p>

          <Formik
            initialValues={{
              email: undefined,
              password: undefined,
              importCleverProfiles: oauthInitiator === OAUTH_INITIATORS.CLEVER,
            }}
            onSubmit={(userCredentials) =>
              login({
                email: email || userCredentials.email,
                password: userCredentials.password,
                importCleverProfiles: userCredentials.importCleverProfiles,
              })
            }
          >
            {({ values, errors, setFieldValue, handleBlur }) => (
              <Form method="post" noValidate>
                <div className="mt-6 space-y-6">
                  {error ? (
                    <AlertBox text={error} className="px-2 py-sm" />
                  ) : null}

                  <div className="flex flex-col bg-white rounded-lg space-y-4">
                    {!email ? (
                      <EmailField
                        name={fields.email}
                        label="Email address"
                        autoFocus={true}
                        validateAsync={async (value) => {
                          // run validation only when email value changes
                          if (values[fields.email] !== value) {
                            try {
                              await validateEmail(value);
                              return undefined;
                            } catch (emailValidationError) {
                              if (emailValidationError?.code === 422) {
                                const { error: errorMessage } =
                                  await emailValidationError.response.json();
                                return errorMessage;
                              }
                              return undefined;
                            }
                          }
                          return errors[fields.email];
                        }}
                      />
                    ) : null}

                    <div className="space-y-1">
                      <PasswordField
                        name={fields.password}
                        label="Password"
                        validate={(value) => {
                          if (value && value.length < 6) {
                            return "Your password must be at least 6 characters";
                          }
                          if (/\s/.test(value)) {
                            return "Your password cannot contain spaces";
                          }
                          return undefined;
                        }}
                      />
                      <div>
                        <Link
                          href={ROUTE_PATHS.FORGOT_PASSWORD}
                          onMouseDown={(e) => e.preventDefault()}
                          className="text-gray-700 text-[12px] underline cursor-pointer"
                        >
                          {/**
                           * A workaround to avoid pressing twice to leave the form.
                           * https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
                           */}
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    {oauthInitiator === OAUTH_INITIATORS.CLEVER_SIGNUP ? (
                      <Checkbox
                        name={fields.importCleverProfiles}
                        label="Would you like to sync classes from Clever?"
                        description="This will add Clever classes to your account."
                        onChange={(val) =>
                          setFieldValue(fields.importCleverProfiles, val)
                        }
                        checked={values.importCleverProfiles}
                        onBlur={handleBlur}
                      />
                    ) : null}
                  </div>

                  <div className="flex flex-row space-x-4 mt-6">
                    <Button
                      variant={BUTTON_VARIANTS.darkOutline}
                      size={BUTTON_SIZES.lg}
                      className="w-full"
                      disabled={isLoading}
                      onPress={onClose}
                    >
                      Nevermind
                    </Button>
                    <Button
                      variant={BUTTON_VARIANTS.dark}
                      size={BUTTON_SIZES.lg}
                      type="submit"
                      disabled={isLoading}
                      className="w-full"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function LoginViaClever() {
  const searchParams = useSearchParams();
  const [connectionCredentials, setConnectionCredentials] = React.useState();
  const { intendedPath } = useIntendedPath();

  const context = JSON.stringify({
    intended_path: intendedPath,
    oauth_initiator: OAUTH_INITIATORS.CLEVER_LOGIN,
  });
  let cleverURL = `${process.env.NEXT_PUBLIC_CLEVER_OAUTH_URL}?response_type=code&redirect_uri=${process.env.NEXT_PUBLIC_API_BASEURL}/universe/v1/oauth/callback/clever&client_id=${process.env.NEXT_PUBLIC_CLEVER_CLIENT_ID}&state=${context}`;
  if (process.env.NEXT_PUBLIC_CLEVER_DISTRICT_ID) {
    cleverURL += `&district_id=${process.env.NEXT_PUBLIC_CLEVER_DISTRICT_ID}`;
  }
  const accountFound = searchParams.get("account_found");
  const email = searchParams.get("email");
  const oauthInitiator = searchParams.get("oauth_initiator");
  const cleverError = searchParams.get("clever_error");

  useLogEvent({
    event: "Login Failed",
    properties: {
      sourceElement:
        oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN
          ? SECTIONS.LOGIN_WITH_CLEVER_BUTTON
          : oauthInitiator === OAUTH_INITIATORS.CLEVER
          ? SECTIONS.LOGIN_WITH_CLEVER_LIBRARY
          : undefined,
      failureReason: cleverError,
    },
    options: {
      enabled: [SSO_ERRORS.LOGIN, SSO_ERRORS.LIBRARY].includes(cleverError),
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  return (
    <>
      {cleverError === SSO_ERRORS.LOGIN ||
      cleverError === SSO_ERRORS.LIBRARY ? (
        <AlertBox
          text="Sorry! We're experiencing some issues with Clever right now. Please try again later."
          className="px-3 py-sm"
        />
      ) : null}
      <SSOButton
        variantStyle="clever"
        onPress={() => {
          window.location.href = cleverURL;
        }}
      >
        Log In with Clever
      </SSOButton>

      {/* Start of connecting new GN account Dialog to Clever */}
      <Dialog.Root
        className="flex flex-col bg-gray-900 justify-normal"
        isOpen={
          oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN &&
          accountFound === "false"
        }
      >
        <Dialog.Title className="gn-headline-md text-white text-center">
          <GoNoodleHeader className="mb-lg" />
          Let&apos;s Get GoNoodling!
        </Dialog.Title>

        <Dialog.Content className="w-full md:w-screen-xs p-md mt-14 mb-8 bg-white rounded-lg text-center transform transition-all">
          <div className="flex flex-col space-y-md">
            <p className="gn-text">
              Do you already have a GoNoodle account? If so, we can connect that
              account to Clever so you can pick up where you left off!
            </p>

            <div className="flex flex-col space-y-sm">
              <RedirectToRegistrationButton
                variant={BUTTON_VARIANTS.dark}
                size={BUTTON_SIZES.md}
                queryParams={{
                  email,
                  account_found: accountFound,
                  oauth_initiator: OAUTH_INITIATORS.CLEVER_SIGNUP,
                }}
              >
                No, I don&apos;t have an account
              </RedirectToRegistrationButton>

              <Button
                variant={BUTTON_VARIANTS.darkOutline}
                size={BUTTON_SIZES.md}
                onPress={() =>
                  setConnectionCredentials({
                    email: undefined,
                  })
                }
              >
                Yes, I already have an account
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      {/* End of connecting new GN account Dialog to Clever */}

      {/* Start of connecting existing GN account Dialog to Clever */}
      <Dialog.Root
        className="flex flex-col bg-gray-900 justify-normal"
        isOpen={
          accountFound === "true" &&
          oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN
        }
      >
        <Dialog.Title className="gn-headline-md text-white text-center">
          <GoNoodleHeader className="mb-lg" />
          Already GoNoodling?
        </Dialog.Title>
        <Dialog.Content className="w-full md:w-screen-xs p-md mt-14 mb-8 bg-white rounded-lg text-center transform transition-all">
          <div className="flex flex-col space-y-md">
            <p className="gn-text">
              We found a GoNoodle account for
              <br />
              <span className="font-extrabold text-black">{email}</span>
              <br />
              Would you like to connect this account to Clever?
            </p>
            <div className="flex flex-col space-y-sm">
              <Button
                variant={BUTTON_VARIANTS.dark}
                size={BUTTON_SIZES.md}
                onPress={() =>
                  setConnectionCredentials({
                    email,
                  })
                }
              >
                Yes, connect this account to Clever
              </Button>

              <RedirectToRegistrationButton
                variant={BUTTON_VARIANTS.darkOutline}
                size={BUTTON_SIZES.md}
              >
                Never mind, I&apos;ll sign up using a different email
              </RedirectToRegistrationButton>

              <Pressable
                className="underline gn-text-sm"
                onPress={() =>
                  setConnectionCredentials({
                    email: undefined,
                  })
                }
              >
                Connect a different GoNoodle account to clever
              </Pressable>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      {/* End of connecting existing GN account Dialog to Clever */}

      {connectionCredentials ? (
        <LoginDialog
          email={connectionCredentials.email}
          title={
            connectionCredentials.email
              ? "Enter your password"
              : "Enter your log in information"
          }
          description={
            connectionCredentials.email ? (
              <>
                We found a GoNoodle account for
                <br />
                <span className="font-extrabold text-black">{email}</span>
                <br />
                Would you like to connect this account to Clever?
              </>
            ) : (
              "To connect an existing GoNoodle account to Clever, please enter your GoNoodle login email and password:"
            )
          }
          onClose={() => setConnectionCredentials(undefined)}
        />
      ) : null}
    </>
  );
}

function LoginViaGoogle() {
  const searchParams = useSearchParams();
  const [connectionCredentials, setConnectionCredentials] = React.useState();
  const { intendedPath } = useIntendedPath();

  const accountFound = searchParams.get("account_found");
  const email = searchParams.get("email");
  const oauthInitiator = searchParams.get("oauth_initiator");
  const googleError = searchParams.get("google_error");

  const context = JSON.stringify({
    intended_path: intendedPath,
    oauth_initiator: OAUTH_INITIATORS.GOOGLE_LOGIN,
  });
  const googleURL = `${process.env.NEXT_PUBLIC_GOOGLE_OAUTH_URL}?response_type=code&redirect_uri=${process.env.NEXT_PUBLIC_API_BASEURL}/universe/v1/oauth/callback/google&client_id=${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}&scope=https%3A//www.googleapis.com/auth/userinfo.profile+https%3A//www.googleapis.com/auth/userinfo.email&state=${context}`;

  useLogEvent({
    event: "Login Failed",
    properties: {
      sourceElement:
        oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN
          ? SECTIONS.LOGIN_WITH_GOOGLE_BUTTON
          : oauthInitiator === OAUTH_INITIATORS.GOOGLE
          ? SECTIONS.LOGIN_WITH_GOOGLE_LIBRARY
          : undefined,
      failureReason: googleError,
    },
    options: {
      enabled: [SSO_ERRORS.LOGIN, SSO_ERRORS.LIBRARY].includes(googleError),
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  return (
    <>
      {googleError === SSO_ERRORS.LOGIN ||
      googleError === SSO_ERRORS.LIBRARY ? (
        <AlertBox
          text="Sorry! We're experiencing some issues with Google right now. Please try again later."
          className="px-3 py-sm"
        />
      ) : null}
      <SSOButton
        variantStyle="google"
        onPress={() => {
          window.location.href = googleURL;
        }}
      >
        Log In with Google
      </SSOButton>

      {/* Start of connecting new GN account Dialog to Google */}
      <Dialog.Root
        className="flex flex-col bg-gray-900 justify-normal"
        isOpen={
          oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN &&
          accountFound === "false"
        }
      >
        <Dialog.Title className="gn-headline-md text-white text-center">
          <GoNoodleHeader className="mb-lg" />
          Let&apos;s Get GoNoodling!
        </Dialog.Title>
        <Dialog.Content className="w-full md:w-screen-xs p-md mt-14 mb-8 bg-white rounded-lg text-center transform transition-all">
          <div className="flex flex-col space-y-md">
            <p className="gn-text">
              Do you already have a GoNoodle account? If so, we can connect that
              account to Google so you can pick up where you left off!
            </p>
            <div className="flex flex-col space-y-sm">
              <RedirectToRegistrationButton
                variant={BUTTON_VARIANTS.dark}
                size={BUTTON_SIZES.md}
                queryParams={{
                  email,
                  account_found: accountFound,
                  oauth_initiator: OAUTH_INITIATORS.GOOGLE_SIGNUP,
                }}
              >
                No, I don&apos;t have an account
              </RedirectToRegistrationButton>

              <Button
                variant={BUTTON_VARIANTS.darkOutline}
                size={BUTTON_SIZES.md}
                onPress={() =>
                  setConnectionCredentials({
                    email: undefined,
                  })
                }
              >
                Yes, I already have an account
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      {/* End of connecting new GN account Dialog to Google */}

      {/* Start of connecting existing GN account Dialog to Google */}
      <Dialog.Root
        className="flex flex-col bg-gray-900 justify-normal"
        isOpen={
          accountFound === "true" &&
          oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN
        }
      >
        <Dialog.Title className="gn-headline-md text-white text-center">
          <GoNoodleHeader className="mb-lg" />
          Already GoNoodling?
        </Dialog.Title>
        <Dialog.Content className="w-full md:w-screen-xs p-md mt-14 mb-8 bg-white rounded-lg text-center transform transition-all">
          <div className="flex flex-col space-y-md">
            <p className="gn-text">
              We found a GoNoodle account for
              <br />
              <span className="font-extrabold text-black">{email}</span>
              <br />
              Would you like to connect this account to Google?
            </p>
            <div className="flex flex-col space-y-sm">
              <Button
                variant={BUTTON_VARIANTS.dark}
                size={BUTTON_SIZES.md}
                onPress={() =>
                  setConnectionCredentials({
                    email,
                  })
                }
              >
                Yes, connect this account to Google
              </Button>

              <RedirectToRegistrationButton
                variant={BUTTON_VARIANTS.darkOutline}
                size={BUTTON_SIZES.md}
              >
                Never mind, I&apos;ll sign up using a different email
              </RedirectToRegistrationButton>

              <Pressable
                className="underline gn-text-sm"
                onPress={() =>
                  setConnectionCredentials({
                    email: undefined,
                  })
                }
              >
                Connect a different GoNoodle account to Google
              </Pressable>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      {/* End of connecting existing GN account Dialog to Google */}

      {connectionCredentials ? (
        <LoginDialog
          email={connectionCredentials.email}
          title={
            connectionCredentials.email
              ? "Enter your password"
              : "Enter your log in information"
          }
          description={
            connectionCredentials.email ? (
              <>
                We found a GoNoodle account for
                <br />
                <span className="font-extrabold text-black">{email}</span>
                <br />
                Would you like to connect this account to Google?
              </>
            ) : (
              "To connect an existing GoNoodle account to Google, please enter your GoNoodle login email and password:"
            )
          }
          onClose={() => setConnectionCredentials(undefined)}
        />
      ) : null}
    </>
  );
}

const LoginCard = () => {
  const fields = {
    email: "email",
    password: "password",
  };
  const { login, error, isLoading } = useLogin({
    redirectRoute: {
      pathname: `/${ROUTE_PREFIX.PROFILES}`,
      referrer: {
        sourceElement: SECTIONS_TYPES.LOGIN,
        sourceName: SECTIONS_TYPES.LOGIN,
      },
    },
  });
  const { user } = useUser();
  const { validateEmail } = useValidateEmail();
  const SSOEnabled = user.features?.clever || user.features?.googleSso;

  const { logEvent: loginFailedEvent } = useLogEvent({
    event: "Login Failed",
    properties: {
      sourceElement: SECTIONS_TYPES.LOGIN,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  return (
    <Formik
      initialValues={{
        email: undefined,
        password: undefined,
      }}
      onSubmit={(userCredentials) =>
        login({
          email: userCredentials.email,
          password: userCredentials.password,
        })
      }
    >
      {({ values, errors }) => (
        <Form noValidate method="post">
          <div
            className={`
            ${!SSOEnabled && "md:w-screen-xs mx-auto"}
             md:w-full space-y-6`}
          >
            {error ? (
              <AlertBox text={error} className="px-2 lg:px-6 py-sm" />
            ) : null}

            <div className="flex flex-col md:flex-row px-4 lg:px-9 py-11 bg-white rounded-lg">
              <div className="flex flex-col flex-1">
                <div className="space-y-4">
                  <EmailField
                    name={fields.email}
                    label="Email address"
                    autoFocus={true}
                    validateAsync={async (value) => {
                      // run validation only when email value changes
                      if (values[fields.email] !== value) {
                        try {
                          await validateEmail(value);
                          return undefined;
                        } catch (emailValidationError) {
                          if (emailValidationError?.code === 422) {
                            const { error: errorMessage } =
                              await emailValidationError.response.json();
                            loginFailedEvent({ failureReason: errorMessage });
                            return errorMessage;
                          }
                          return undefined;
                        }
                      }
                      return errors[fields.email];
                    }}
                  />
                  <div className="space-y-1">
                    <PasswordField
                      name={fields.password}
                      label="Password"
                      validate={(value) => {
                        if (value && value.length < 6) {
                          return "Your password must be at least 6 characters";
                        }
                        if (/\s/.test(value)) {
                          return "Your password cannot contain spaces";
                        }
                        return undefined;
                      }}
                    />
                    <div>
                      <Link
                        href={ROUTE_PATHS.FORGOT_PASSWORD}
                        onMouseDown={(e) => e.preventDefault()}
                        className="text-gray-700 text-[12px] underline cursor-pointer"
                      >
                        {/**
                         * A workaround to avoid pressing twice to leave the form.
                         * https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
                         */}
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>

                <Button
                  variant={BUTTON_VARIANTS.vivid}
                  size={BUTTON_SIZES.lg}
                  type="submit"
                  disabled={isLoading}
                  className="mt-6"
                >
                  Log In
                </Button>

                <div className="flex flex-row items-center space-x-sm mt-6">
                  <span className="flex-1 border-t-sm border-dashed border-gray-500" />
                  <p className="text-center font-bold gn-text-sm text-gray-500">
                    OR
                  </p>
                  <span className="flex-1 border-t-sm border-dashed border-gray-500" />
                </div>

                <div className="mt-6 flex flex-col space-y-4">
                  <p className="text-center gn-text-lg font-bold mx-2">
                    Don&apos;t have an account?
                  </p>

                  <RedirectToRegistrationButton
                    variant={BUTTON_VARIANTS.darkOutline}
                    size={BUTTON_SIZES.lg}
                    referrer={{
                      sourceElement: SECTIONS_TYPES.LOGIN,
                      sourceName: SECTIONS.CREATE_ACCOUNT_BUTTON,
                    }}
                  >
                    {/**
                     * A workaround to avoid pressing twice to leave the form.
                     * https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
                     */}
                    <span onMouseDown={(e) => e.preventDefault()}>
                      Create a Free Account
                    </span>
                  </RedirectToRegistrationButton>
                </div>
              </div>
              {SSOEnabled && (
                <>
                  <div className="hidden md:block mx-7 border-r-sm border-gray-500" />

                  <div className="flex flex-col space-y-6 flex-1 mt-6 md:mt-0">
                    <div className="flex flex-row items-center ">
                      <span className="md:hidden flex-1 border-t-sm border-dashed border-gray-500" />
                      <p className="text-center font-bold gn-text-sm text-gray-500 uppercase mx-auto">
                        Or Log In with
                      </p>
                      <span className="md:hidden flex-1 border-t-sm border-dashed border-gray-500" />
                    </div>

                    {user.features?.clever && <LoginViaClever />}
                    {user.features?.googleSso && <LoginViaGoogle />}
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-row justify-center space-x-sm text-gray-300 gn-text-xs ">
              <PrivacyPolicyLink className="ml-5" />
              <p> | </p>
              <TermsOfServiceLink />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default function LoginPage() {
  useLogEvent({
    event: "Login Flow Entered",
  });

  return (
    <Layout
      pageTitle="Login"
      pageDescription="Login Page"
      footer={false}
      header={false}
    >
      <GoNoodleHeader />

      <div className="w-full flex flex-col px-4 py-lg md:px-6 lg:mx-auto lg:w-3/5">
        <div className="text-center mb-8 md:mb-10">
          <h1 className="gn-headline-md text-white">Log In</h1>
        </div>

        <LoginCard />
      </div>
    </Layout>
  );
}

export const getServerSideProps = withAuthorization(
  () => ({
    props: {},
  }),
  ROUTE_PERMISSIONS.ANONYMOUS,
);
